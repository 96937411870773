import React, { useEffect, useState } from "react";
import NavBar from "../../components/navigation-header";
import Footer from "../../components/footer";
import "../../styling/component.css";
import RigthtPage from "../../components/rigtht-page";
import { Link } from "gatsby";

export default function Template({ pageContext }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { newsData } = pageContext;

  // Number of items per page
  const itemsPerPage = 10;

  // State to track the current page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Slice the data based on the current page
  const displayedData = newsData?.data.slice(startIndex, endIndex);

  // Calculate total number of pages
  const totalPages = Math.ceil(newsData?.data.length / itemsPerPage);

  // Function to handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0); // Scroll to the top when the page changes
  };

  return (
    <div style={{ backgroundColor: "#F5F5F5" }}>
      <NavBar />
      <div className="pc">
        <center>
          <div className="store">
            <table
              style={{
                width: "90%",
                textAlign: "start",
                marginTop: "150px",
                marginBottom: "30px",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ width: "70%", verticalAlign: "top" }}>
                    <div style={{ backgroundColor: "#fff" }}>
                      <div style={{ padding: "3em" }}>
                        <h1 className="categories-topic">
                          {newsData?.datewithyear}
                        </h1>
                      </div>
                      <div
                        style={{
                          height: "3px",
                          backgroundColor: "#F5F5F5",
                          width: "100%",
                        }}
                      ></div>
                      {displayedData?.map((data, key) => (
                        <div key={key}>
                          <div style={{ padding: "3em" }}>
                            <a className="para-link" href={data.readmore}>
                              <img
                                className="news-image"
                                src={data?.aimage}
                                alt="news-image"
                              />
                            </a>
                            <p className="imageunderpara">
                              {data?.imageunderpara}
                            </p>
                            <a className="para-link" href={data.readmore}>
                              <h1 className="categories-topic">
                                {data?.topic}
                              </h1>
                            </a>
                            <p className="categories-para">
                              <a href={data?.link1}>{data?.link1n}</a> /{" "}
                              <a href={data?.link2}>{data?.link2n}</a> /{" "}
                              <a href={data?.link3}>{data?.link3n}</a>
                            </p>
                            <p className="categories-para">{data?.para}</p>
                            <p className="categories-para">
                              <Link to={data?.readmore}>Read More »</Link>
                            </p>
                          </div>
                          <div
                            style={{
                              height: "3px",
                              backgroundColor: "#F5F5F5",
                              width: "100%",
                            }}
                          ></div>
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      {/* Render Previous Page button if not on the first page */}
                      {currentPage !== 1 && (
                        <div style={{ position: "absolute", left: 0 }}>
                          <button
                            className="link-button-left"
                            id="button"
                            onClick={() => setCurrentPage(currentPage - 1)}
                          >
                            ← Previous Page
                          </button>
                        </div>
                      )}

                      {/* Render page numbers only if there are more than one page */}
                      {totalPages > 1 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          {Array.from({ length: totalPages }, (_, index) => (
                            <button
                              key={index + 1}
                              onClick={() => handlePageChange(index + 1)}
                              id="button"
                              style={{
                                width: "35px",
                                height: "35px",
                                textAlign: "center",
                                borderColor: "#0370b8",
                                margin: "0 5px", // Add some margin between page numbers
                                backgroundColor:
                                  currentPage === index + 1
                                    ? "#0370b8"
                                    : "white", // Set background color for the current page
                                color:
                                  currentPage === index + 1
                                    ? "white"
                                    : "#0370b8", // Set text color for the current page
                              }}
                            >
                              {index + 1}
                            </button>
                          ))}
                        </div>
                      )}

                      {/* Render Next Page button if not on the last page */}
                      {currentPage !== totalPages && (
                        <div style={{ position: "absolute", right: 0 }}>
                          <button
                            className="link-button-right"
                            id="button"
                            onClick={() => setCurrentPage(currentPage + 1)}
                          >
                            Next Page →
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                  <td style={{ width: "auto", verticalAlign: "top" }}>
                    <div style={{ marginLeft: "60px", marginRight: "60px" }}>
                      <RigthtPage />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </center>
      </div>
      <></>
      <div className="mobile">
        <div
          style={{
            marginTop: "50px",
            marginLeft: "10px",
            marginRight: "10px",
            marginBottom: "20px",
          }}
        >
          <div style={{ backgroundColor: "#fff" }}>
            <div style={{ padding: "2em" }}>
              <h1 className="categories-topic">{newsData?.datewithyear}</h1>
            </div>
            <div
              style={{
                height: "3px",
                backgroundColor: "#F5F5F5",
                width: "100%",
              }}
            ></div>
            {displayedData?.map((data, key) => (
              <div key={key}>
                <div style={{ padding: "2em" }}>
                  <Link to={`/${data.readmore}/`} className="tpoic-link">
                    <img
                      className="news-image"
                      src={data?.aimage}
                      alt="news-image"
                    />
                  </Link>
                  <Link to={`/${data.readmore}/`} className="tpoic-link">
                    <h1 className="categories-topic">{data?.topic}</h1>
                  </Link>

                  <p className="categories-para">
                    <a href={data?.link}>{data?.link1n}</a> /{" "}
                    <a href={data?.link2}>{data?.link2n}</a> /{" "}
                    <a href={data?.link3}>{data?.link3n}</a>
                  </p>
                  <p className="categories-para">{data?.para}</p>
                  <p className="categories-para">
                    <Link to={data?.readmore}>Read More »</Link>
                  </p>
                </div>
                <div
                  style={{
                    height: "3px",
                    backgroundColor: "#F5F5F5",
                    width: "100%",
                  }}
                ></div>
              </div>
            ))}
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative",
            }}
          >
            {/* Render Previous Page button if not on the first page */}
            {currentPage !== 1 && (
              <div style={{ position: "absolute", left: 0 }}>
                <button
                  className="link-button-left"
                  id="button"
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  ← Previous Page
                </button>
              </div>
            )}

            {/* Render page numbers only if there are more than one page */}
            {totalPages > 1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => handlePageChange(index + 1)}
                    id="button"
                    style={{
                      width: "35px",
                      height: "35px",
                      textAlign: "center",
                      borderColor: "#0370b8",
                      margin: "0 5px", // Add some margin between page numbers
                      backgroundColor:
                        currentPage === index + 1 ? "#0370b8" : "white", // Set background color for the current page
                      color: currentPage === index + 1 ? "white" : "#0370b8", // Set text color for the current page
                    }}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            )}

            {/* Render Next Page button if not on the last page */}
            {currentPage !== totalPages && (
              <div style={{ position: "absolute", right: 0 }}>
                <button
                  className="link-button-right"
                  id="button"
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  Next Page →
                </button>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            marginLeft: "30px",
            marginRight: "30px",
            marginBottom: "80px",
          }}
        >
          <RigthtPage />
        </div>
      </div>
      <Footer />
    </div>
  );
}
